


























import Vue from 'vue';

export default Vue.extend({
  name: 'Disclaimer',
  computed: {
    noticeParagraphs(): string[] {
      const triage = this.$t('TriageNotice') as string;
      return triage.split('\n\n');
    },
  },
});
