<template>
  <div>
    <Disclaimer />
  </div>
</template>

<script>
// @ is an alias to /src
import Disclaimer from '@/components/Disclaimer/Disclaimer.vue';

export default {
  name: 'DisclaimerPage',
  components: {
    Disclaimer,
  },
};
</script>
